import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, Button, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LockIcon from '@material-ui/icons/Lock';
import { MdOutlineForkRight } from "react-icons/md";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ArrowDropDown } from "@material-ui/icons";
import NotifyAlert from "../styled/CommonComponents/NotifyAlert";
import ApprovalAndActions from "../approval/ApprovalAndActions";
import ApprovalActionDrawer from "../approval/ApprovalActionDrawer";
import { allStatusOptions } from "../../helpers/allStatuses";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import { getDetailsObservation, updateObservation } from "./Api.call";
import { getAllFiles } from "../styled/CommonComponents/api.call";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import FilesViewer from "../styled/generic/FilesViewer";
import "../doc/editor.css"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white",
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    mainBody: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 25px 20px",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 10px 50px",
        },
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px",
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    optionsCont: {
        padding: "5px",
    },
    typeSingleOptionSty: {
        padding: "4px 15px",
        fontSize: "16px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec",
        },
    },
}));

export default function EditObservation() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { observationId } = useParams();

    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);

    const [observation, setObservation] = useState();
    const [loader, setLoader] = useState(false);
    const [submitCheckValidation, setSubmitCheckValidation] = useState();
    const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
    const [openAction, setOpenAction] = useState(false);
    const [needToMakeDisable, setNeedToMakeDisable] = useState(false);
    const [actionStatus, setActionStatus] = useState(null)
    const [activeStage, setActiveStage] = useState(null)
    const [needToMakeStatusDisable, setNeedToMakeStatusDisable] = useState(true)
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState([]);
    const [newFileIds, setNewFileIds] = useState([]);
    const [updatedFileIds, setUpdatedFileIds] = useState({});

    //get populated observation data
    const getPopulatedObservationData = async () => {
        setLoader(true);
        await getDetailsObservation({ observationId })
            .then((data) => {
                console.log(data)
                if (data?._id) {
                    setObservation(data);
                    setTitle(data.title || '');
                    setDescription(data.description || '');
                    setFiles(data.docs?.flatMap(doc => doc.files.map(file => ({
                        ...file,
                        title: doc?.title || "",
                        description: doc?.description || "",
                        docId: doc._id
                    }))) || []);
                    setNewFileIds([])
                    setUpdatedFileIds({})
                }
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    }

    //get that data with useEffect
    useEffect(() => {
        if (observationId) {
            getPopulatedObservationData()
        }
    }, [observationId]);

    //to get uploaded files
    useEffect(() => {
        if (createdFileIds) {
            getAllFiles({ files: createdFileIds })
                .then((data) => {
                    if (data && data.length > 0) {
                        setFiles(prevFiles => [...prevFiles, ...data]);
                        setNewFileIds(prevIds => [...prevIds, ...createdFileIds]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [createdFileIds]);

    //to handle remove files
    const handleRemoveFile = (id) => {
        setFiles(prevFiles => prevFiles.filter(file => file._id !== id));
    };

    //handle file title and description on change
    const handleMetadataChange = (fileId, key, value) => {
        setFiles(prevFiles => prevFiles.map(file =>
            file._id === fileId ? { ...file, [key]: value } : file
        ));
        setUpdatedFileIds(prevIds => ({
            ...prevIds,
            [fileId]: {
                ...prevIds[fileId],
                [key]: value
            }
        }));
    };

    //to check access with approval flow
    useEffect(() => {
        let locActiveStage = observation?.approvalRequest?.activeStage || null
        setActiveStage(locActiveStage)
        let canEdit = false
        if (locActiveStage?.approvalType === "anybody") {
            canEdit = true
        } else {
            let approvers = locActiveStage?.approvers || [];
            for (let i = 0; i < approvers.length; i++) {
                if (approvers[i].profile == user?.profile && !approvers[i]?.isApproved && !approvers[i]?.isRejected) {
                    canEdit = true;
                    break;
                }
            }
        }
        setNeedToMakeStatusDisable(!canEdit)
        if (locActiveStage?.canEditMainData && canEdit) {
            setNeedToMakeDisable(false)
        } else {
            setNeedToMakeDisable(true)
        }
    }, [observation?.approvalRequest?.activeStage])

    //approval process to update status where we also check validation of the certificate
    const updateStatus = (nextStatus) => {
        if (!needToMakeStatusDisable) {
            if (title && title.length > 2) {
                console.log("get call 456")
                setActionStatus(nextStatus)
                setOpenAction(true)
            } else {
                setSubmitCheckValidation({
                    show: true,
                    message: "Title must be at least 3 characters",
                    status: "error",
                });
            }
        }
    }

    //todo: have to review the tx create logic
    const submit = async () => {
        if (!needToMakeDisable) {
            if (title && title.length > 2) {
                try {
                    setLoader(true);
                    let updateObservationObj = {
                        ...observation,
                        title,
                        description,
                        files: files.map(file => ({
                            _id: file._id,
                            docId: file.docId,
                            title: file.title,
                            description: file.description,
                            tag: file.tag
                        }))
                    }
                    if ('status' in updateObservationObj) {
                        delete updateObservationObj.status;
                    }
                    await updateObservation({
                        observations: [{
                            ...observation,
                            title,
                            description,
                            files: files.map(file => ({
                                _id: file._id,
                                docId: file.docId,
                                title: file.title,
                                description: file.description,
                                tag: file.tag
                            }))
                        }],
                        newFileIds,
                        updatedFileIds
                    })
                        .then((data) => {
                            console.log(data);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(async () => {
                            await getPopulatedObservationData()
                            setLoader(false);
                        })
                } catch (error) {
                    setSubmitCheckValidation({
                        show: true,
                        message: "Failed to update observation. Please try again.",
                        status: "error",
                    });
                    console.error("Error updating observation:", error);
                }
            } else {
                setSubmitCheckValidation({
                    show: true,
                    message: "Title must be at least 3 characters",
                    status: "error",
                });
            }
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <div className={classes.barLeft}>
                    <IconButton
                        className={classes.iconButtonStyle}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>
                    <Typography className={classes.headerTitle}>Observation</Typography>
                </div>
                <div className={classes.barRight}>
                    <Button
                        id="demo-customized-button"
                        variant="outlined"
                        size="small"
                        style={{ marginLeft: "10px" }}
                        color="primary"
                        onClick={() => { setOpenApprovalFlow(true) }}
                        endIcon={<MdOutlineForkRight />}
                    >
                        Approval Flow
                    </Button>
                    <ApprovalAndActions
                        openApprovalFlow={openApprovalFlow}
                        setOpenApprovalFlow={setOpenApprovalFlow}
                        loadingBool={loader}
                        setLoadingBool={setLoader}
                        parent={observationId}
                        origin={"observation"}
                        parentModelName={"SiteObservation"}
                        needToMakeDisable={needToMakeStatusDisable}
                        activeStage={activeStage}
                        organizationId={observation?.organization?._id}
                        projectId={observation?.project?._id}
                        requestId={observation?.approvalRequest?._id}
                        templateId={observation?.approvalRequest?.approvalTemplate?._id}
                    />
                    <div style={{ width: "10px" }}></div>
                    {needToMakeStatusDisable ? (
                        <Button
                            id="demo-customized-button"
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled
                            endIcon={<LockIcon />}
                        >
                            {observation?.status ? observation?.status : "STATUS"}
                        </Button>
                    ) : (<>
                        <MyPopOver
                            closeOnClick={true}
                            appearContent={
                                <Button
                                    id="demo-customized-button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    endIcon={<ArrowDropDown />}
                                >
                                    {observation?.status ? observation?.status : "STATUS"}
                                </Button>
                            }
                            showContent={<div className={classes.optionsCont} >
                                {allStatusOptions["Site Observation"][observation?.status] && allStatusOptions["Site Observation"][observation?.status].map((statusOption, i) => (
                                    <div
                                        key={i}
                                        className={classes.typeSingleOptionSty}
                                        onClick={() => { updateStatus(statusOption) }}
                                    >
                                        {statusOption}
                                    </div>
                                ))}
                            </div>}
                        />
                        <ApprovalActionDrawer
                            openAction={openAction}
                            setOpenAction={setOpenAction}
                            loadingBool={loader}
                            setLoadingBool={setLoader}
                            actionType={"takeAction"}
                            parent={observationId}
                            origin={"observation"}
                            parentModelName={"SiteObservation"}
                            statusField={"status"}
                            statusValue={actionStatus}
                            organizationId={observation?.organization?._id}
                            projectId={observation?.project?._id}
                            requestId={observation?.approvalRequest?._id}
                            templateId={observation?.approvalRequest?.approvalTemplate?._id}
                            activeStage={activeStage}
                            actionCallBack={async (type, curStatues) => {
                                if (!needToMakeDisable) {
                                    submit()
                                }else{
                                    getPopulatedObservationData()
                                }
                              
                            }}
                        />
                    </>)}
                    {needToMakeDisable ? (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            disabled
                            style={{ marginLeft: "10px" }}
                        >
                            Submit
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={submit}
                            style={{ marginLeft: "10px" }}
                        >
                            Save
                        </Button>
                    )}
                </div>
            </div>
            <div>{loader ? <LinearProgress /> : null}</div>
            <div className={classes.mainBody}>
                <TextField
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    margin="normal"
                />
                <ReactQuill
                    value={description}
                    onChange={setDescription}
                    theme="snow"
                    className="EditorStyle1"
                    placeholder="Description"
                />
                <Box mt={2}>
                    <CustomFileUploadButton
                        showComponent={
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: '79px',
                                    padding: '16px 20px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px',
                                    flexShrink: 0,
                                    borderRadius: '10px',
                                    border: '1px dashed #ABBED1',
                                    background: '#F5F7FA',
                                }}
                            >
                                <img
                                    src="/icons/app/document-upload.svg"
                                    style={{ width: '28px', height: '28px' }}
                                    alt="upload icon"
                                />
                            </Box>
                        }
                        parentType="SiteObservation"
                        parentId={observationId}
                        fileNum={25}
                        givenMaxSize={26214400}
                        closeFunCall={() => {
                            dispatch({ type: 'FileUploadReset' });
                            dispatch({ type: 'FileReset' });
                        }}
                    />
                </Box>
                <Box mt={2}>
                    <FilesViewer
                        files={files}
                        isDeletable={true}
                        deleteFunction={handleRemoveFile}
                        disablePreview={true}
                        disableShowMetaData={false}
                        disableEditMetaData={false}
                        onMetadataChange={handleMetadataChange}
                    />
                </Box>
            </div>
            <NotifyAlert
                alertObj={submitCheckValidation}
                setAlertObj={setSubmitCheckValidation}
            />
        </div>
    );
}