import React, { useMemo, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { Box, Typography, Button, IconButton, Dialog, DialogTitle } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Api from '../../../helpers/Api';
import moment from 'moment';

const useStyles = makeStyles({
  container: {
    width: '100%',
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '2px solid #e0e0e0',
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    position: 'relative',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
    position: 'relative',
  },
  taskName: {
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    width: '80px',
    textAlign: 'right',
  },
  cumulativeProgress: {
    width: '120px',
    textAlign: 'right',
    marginRight: '20px',
  },
  date: {
    width: '120px',
    textAlign: 'right',
    marginRight: '20px',
  },
  button: {
    width: '100px',
    textAlign: 'center',
  },
  actions: {
    width: '160px',
    position: 'absolute',
    right: 0,
    backgroundColor: 'inherit',
    paddingLeft: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionsContainer: {
    position: 'absolute',
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    backgroundColor: 'inherit',
  }
});

const TargetAnalytics = ({ targetId, tasks }) => {
  const classes = useStyles();
  const [expandedTasks, setExpandedTasks] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const toggleExpand = (taskId) => {
    setExpandedTasks(prev => ({...prev, [taskId]: !prev[taskId]}));
  };

  // Build the flattened tree structure with depth information
  const items = useMemo(() => {
    const taskMap = {};
    tasks.forEach((task) => {
      task.children = [];
      taskMap[task._id] = task;
    });

    const roots = [];
    tasks.forEach((task) => {
      if (task.parentTask && taskMap[task.parentTask]) {
        taskMap[task.parentTask].children.push(task);
      } else {
        roots.push(task);
      }
    });

    const flatten = (nodes, depth = 0) => {
      let result = [];
      nodes.forEach((node) => {
        result.push({ ...node, depth });
        if (node.children.length > 0) {
          result = result.concat(flatten(node.children, depth + 1));
        }
      });
      return result;
    };

    return flatten(roots);
  }, [tasks]);

  const visibleItems = useMemo(() => {
    return items.filter(item => {
      if (item.depth === 0) return true;
      let parent = items.find(i => i._id === item.parentTask);
      while (parent) {
        if (!expandedTasks[parent._id]) return false;
        parent = items.find(i => i._id === parent.parentTask);
      }
      return true;
    });
  }, [items, expandedTasks]);

  const getItemSize = () => 50;

  const handleDialogOpen = (task) => {
    setSelectedTask(task);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedTask(null);
  };

  const handleDeleteTask = async (deleteChildren) => {
    try {
      // await Api.post('wbs/deleteTask', {
      //   taskId: selectedTask._id,
      //   deleteChildren
      // });
      // Refresh tasks list
      // window.location.reload();
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
    handleDialogClose();
  };

  const TableHeader = () => (
    <Box className={classes.header}>
      <Typography className={classes.taskName}>Task Name</Typography>
      <Typography className={classes.progress}>Progress</Typography>
      <Typography className={classes.cumulativeProgress}>Cumulative Progress</Typography>
      <Typography className={classes.date}>Start Date</Typography>
      <Typography className={classes.date}>End Date</Typography>
      <Typography className={classes.actions}>Actions</Typography>
    </Box>
  );

  const Row = ({ index, style }) => {
    const item = visibleItems[index];
    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedTasks[item._id];

    return (
      <Box style={style} className={classes.row}>
        <Box className={classes.taskName} style={{ paddingLeft: item.depth * 20 }}>
          {hasChildren && (
            <IconButton size="small" onClick={() => toggleExpand(item._id)}>
              {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
          <Typography>{item.taskName}</Typography>
        </Box>
        <Typography className={classes.progress}>{item.progress}%</Typography>
        <Typography className={classes.cumulativeProgress}>{item.c_p}%</Typography>
        <Typography className={classes.date}>
          {moment(item.ps).format('DD/MM/YYYY')}
        </Typography>
        <Typography className={classes.date}>
          {moment(item.pf).format('DD/MM/YYYY')}
        </Typography>
        <Box className={classes.actionsContainer}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<OpenInNewIcon />}
            onClick={() => window.open(`/planning/task/${item._id}`, '_blank')}
          >
            Details
          </Button>
          <IconButton
            size="small"
            onClick={() => handleDialogOpen(item)}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <Box className={classes.container}>
      <TableHeader />
      <List
        height={600}
        width="100%"
        itemCount={visibleItems.length}
        itemSize={getItemSize}
        overscanCount={5}
      >
        {Row}
      </List>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedTask ? `Task: ${selectedTask.taskName} 😊` : ''}
        </DialogTitle>
      </Dialog>
    </Box>
  );
};

export default TargetAnalytics;
