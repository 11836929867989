import React, { useState } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Typography,
  Tooltip,
  tooltipClasses,
  IconButton,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { getProgressColor } from "../../../../helpers/colorHelper";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import AddTaskDialog from "./AddTaskDialog";
import Api from "../../../../helpers/Api";
import { useHistory } from "react-router-dom";
import TaskProgressUpdateDrawer from "./TaskProgressUpdateDrawer";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const TaskCardDetail = ({ task, onUpdateClick, onTaskUpdate, onTaskDelete }) => {
  const formattedDate = new Date(task?.createdAt).toLocaleDateString();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);
  const [showUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [taskToUpdate, setTaskToUpdate] = useState(task);

  function numberWithCommas(x) {
    return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
  }

  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setShowEditTaskDialog(true);
    handleOptionsClose();
  };

  const handleDelete = async () => {
    try {
      await Api.post("/wbs/delete", { taskIdStart: [task._id] });
      onTaskDelete(task);
      history.goBack();
    } catch (error) {
      console.error("Error deleting task:", error);
      // You might want to show an error message to the user here
    }
    handleOptionsClose();
  };

  const handleUpdateClick = () => {
    setTaskToUpdate(task);
    setShowUpdateTaskDialog(true);
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date instanceof Date && !isNaN(date) ?
      date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: '2-digit' }) : null;
  };

  const renderDates = () => {
    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      return date instanceof Date && !isNaN(date) ?
        date.toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: '2-digit' }) : null;
    };

    const plannedStart = formatDate(task?.plannedStart);
    const plannedFinish = formatDate(task?.plannedFinish);
    const startDate = formatDate(task?.startDate);
    const endDate = formatDate(task?.endDate);
    const expectedCompletion = task?.startDate && task?.duration ?
      formatDate(new Date(new Date(task.startDate).getTime() + task.duration * 24 * 60 * 60 * 1000)) :
      null;

    const dateContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    };

    const dateRowStyle = {
      display: 'flex',
      gap: '16px'
    };

    const dateItemStyle = {
      display: 'flex',
      alignItemStart: 'center',
      gap: '4px'
    };

    const labelStyle = {
      color: '#666',
      fontWeight: 'bold',
      fontSizEnd: '0.9rem'
    };

    const valueStyle = {
      color: '#333',
      fontSizEnd: '0.9rem'
    };

    if (task?.progress === 0) {
      return (
        <Box sx={dateContainerStyle}>
          <Box sx={dateRowStyle}>
            {plannedStart && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Planned Start:</span>
                <span style={valueStyle}>{plannedStart}</span>
              </Box>
            )}
            {plannedFinish && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Planned Finish:</span>
                <span style={valueStyle}>{plannedFinish}</span>
              </Box>
            )}
          </Box>
        </Box>
      );
    } else if (task?.progress > 0 && task?.progress < 100) {
      return (
        <Box sx={dateContainerStyle}>
          <Box sx={dateRowStyle}>
            {plannedStart && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Planned Start:</span>
                <span style={valueStyle}>{plannedStart}</span>
              </Box>
            )}
            {plannedFinish && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Planned Finish:</span>
                <span style={valueStyle}>{plannedFinish}</span>
              </Box>
            )}
          </Box>
          <Box sx={dateRowStyle}>
            {startDate && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Start:</span>
                <span style={valueStyle}>{startDate}</span>
              </Box>
            )}
            {expectedCompletion && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Expected End:</span>
                <span style={valueStyle}>{expectedCompletion}</span>
              </Box>
            )}
          </Box>
        </Box>
      );
    } else if (task?.progress === 100) {
      return (
        <Box sx={dateContainerStyle}>
          <Box sx={dateRowStyle}>
            {plannedStart && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Planned Start:</span>
                <span style={valueStyle}>{plannedStart}</span>
              </Box>
            )}
            {plannedFinish && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Planned Finish:</span>
                <span style={valueStyle}>{plannedFinish}</span>
              </Box>
            )}
          </Box>
          <Box sx={dateRowStyle}>
            {startDate && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>Start:</span>
                <span style={valueStyle}>{startDate}</span>
              </Box>
            )}
            {endDate && (
              <Box sx={dateItemStyle}>
                <span style={labelStyle}>End:</span>
                <span style={valueStyle}>{endDate}</span>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ backgroundColor: 'white', p: 3, borderRadiuStart: '12px' }}>
      <BoxSpaceBetween>
        <Box sx={{ display: 'flex', alignItemStart: 'center', gap: 2 }}>
          <Avatar
            alt={task?.creator?.parent?.displayName}
            src={task?.creator?.parent?.displayPicture?.url}
            sx={{ width: 48, height: 48 }}
          />
          <Box>
            <Typography variant="h6">{task?.creator?.parent?.displayName}</Typography>
            <Typography variant="body2" color="text.secondary">
              {formatDate(task?.createdAt)}
            </Typography>
          </Box>
        </Box>
        {renderDates()}
        <IconButton onClick={handleOptionsClick}>
          <MoreVertIcon />
        </IconButton>
      </BoxSpaceBetween>

      <Typography variant="h4" sx={{ my: 3 }}>{task?.taskName}</Typography>

      {task?.description && (
        <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
          {task.description}
        </Typography>
      )}

      {task?.target?.project?.name && (
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Project: {task?.project?.displayName}
        </Typography>
      )}

      {task?.target?.blocks && task.target.blocks.length > 0 && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>BlockStart:</Typography>
          <HorizBox>
            {task?.projectBlocks.map((block) => (
              <Box
                key={block?._id}
                sx={{
                  backgroundColor: "#f0f0f0",
                  color: "#333",
                  padding: "4px 8px",
                  borderRadiuStart: "10px",
                  fontSizEnd: '0.875rem',
                  marginRight: '8px',
                }}
              >
                {block?.name}
              </Box>
            ))}
          </HorizBox>
        </Box>
      )}

      {(task?.assigned && task?.assigned.length > 0) && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>Assigned to:</Typography>
          <div style={{ display: "flex" }} >
            <AvatarGroup max={10}>
              {task?.assigned?.map(({ parent }) => (
                <BootstrapTooltip key={parent?.id} title={parent?.displayName} placement="top">
                  <Avatar
                    alt={parent?.displayName}
                    src={parent?.displayPicture?.thumbUrl || parent?.displayPicture?.url}
                    sx={{ width: 30, height: 30 }}
                  />
                </BootstrapTooltip>
              ))}
            </AvatarGroup>
          </div>
        </Box>
      )}

      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>Linked Amount:</Typography>
        <div style={{ display: "flex" }} >
          <h3>₹{numberWithCommas(task?.linkedAmount)}</h3>
        </div>
      </Box>

      <Box sx={{ mb: 3 }}>
        <HorizBox sx={{ alignItems: 'center' }}>
          <HorizBox sx={{
            overflowX: 'auto',
            flexWrap: 'nowrap',
            '&::-webkit-scrollbar': { height: '6px' },
            '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
            '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '3px' }
          }}>
            {[...(task?.labels ?? []), ...(task?.structures ?? [])]
              .filter(Boolean)
              .filter((item, index, self) =>
                item?._id && index === self.findIndex((t) => t?._id === item?._id)
              )
              .map((item) => (
                <Box
                  key={item?._id}
                  sx={{
                    backgroundColor: (item?.colorCode ?? "#666") + "33",
                    color: item?.colorCode ?? "#666",
                    padding: "4px 8px",
                    borderRadius: "10px",
                    fontSize: '0.875rem',
                    whiteSpace: 'nowrap',
                    flexShrink: 0,
                    marginRight: '8px',
                    '&:last-child': { marginRight: 0 }
                  }}
                >
                  {item?.name ?? "Unnamed"}
                </Box>
              ))}
          </HorizBox>
        </HorizBox>
      </Box>

      <Box
        sx={{
          backgroundColor: "#d1d5db",
          p: 2,
          borderRadiuStart: "10px",
          position: "relative",
          height: "60px",
          display: "flex",
          alignItemStart: "center",
        }}
      >
        <Box
          sx={{
            width: `${task?.progress || 0}%`,
            backgroundColor: getProgressColor(task?.progress)?.backgroundColor,
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            borderRadiuStart: "10px 0 0 10px",
          }}
        />
        <BoxSpaceBetween sx={{ width: "100%", zIndex: 1 }}>
          <HorizBox>
            <Typography
              variant="h6"
              sx={{
                color: getProgressColor(task?.progress)?.color,
              }}
            >
              {task?.progress || 0}%
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: getProgressColor(task?.progress)?.color,
              }}
            >
              Progress
            </Typography>
          </HorizBox>
          <Button
            variant="contained"
            onClick={handleUpdateClick}
            sx={{
              backgroundColor: "white",
              color: getProgressColor(task?.progress)?.color,
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            Update
          </Button>
        </BoxSpaceBetween>
      </Box>

      {/* Vert Options Menu */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            startIcon={<EditOutlined />}
            onClick={handleEdit}
            fullWidth
            sx={{ justifyContent: 'flex-start', mb: 1 }}
          >
            Edit
          </Button>
          <Button
            startIcon={<DeleteOutline />}
            onClick={handleDelete}
            fullWidth
            sx={{ justifyContent: 'flex-start' }}
          >
            Delete
          </Button>
        </Box>
      </Popover>

      {/* Edit Task Dialog */}
      <AddTaskDialog
        showAddTaskDialog={showEditTaskDialog}
        setShowAddTaskDialog={setShowEditTaskDialog}
        targetId={task?.target?._id}
        onTaskUpdate={(updatedTask) => {
          onTaskUpdate(updatedTask);
          setShowEditTaskDialog(false);
        }}
        profileId={task?.target?.organization?.profile}
        library={task?.target?.buildingCodeLibrary}
        project={task?.target?.project}
        organization={task?.target?.organization}
        taskToUpdate={task}
      />

      <TaskProgressUpdateDrawer
        open={showUpdateTaskDialog}
        setOpen={setShowUpdateTaskDialog}
        setTask={(updatedTask) => {
          setTaskToUpdate(updatedTask)
          onTaskUpdate(updatedTask);
        }}
        taskToUpdate={taskToUpdate}
        isInDetail={true}
      />
    </Box>
  );
};

export default TaskCardDetail;

