import {
    Box, Grid, MenuItem, Pagination, Skeleton
} from "@mui/material";
import { TextField } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import Button from "../styled/generic/Button";
import SearchField from "../styled/generic/SearchField";
import Select from "../styled/generic/Select";
import TaskCard from "./tasks/components/TaskCard";
import { getObservations, getOptionsForObservationsFilter, getTaskForParent } from "./api.call";
import { useDebounce } from "react-use";
import { FaFilter } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import ObservationCard from "../sitereport/ObservationCard";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: "white"
    },
    header: {
        width: "100%",
        height: "50px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "0px 20px"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 100px)",
        display: "flex",
        flexWrap: "wrap",
        overflowY: "auto",
        padding: "20px",
        alignContent: "flex-start"
    },
    emptyLibrarayCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "200px",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue",
        },
    },
    drawerBody: {
        width: "100%",
        padding: "20px",
        height: "calc(100% - 80px)",
        overflowY: "auto",
    },
    drawerBottomBtn: {
        width: "100%",
        height: "80px",
        paddingBottom: "20px",
        padding: "0px 20px",
    },
    organizationCover: {
        position: "relative",
        width: "100%",
    },
    editOrg: {
        width: "fit-content",
        position: "absolute",
        right: "1rem",
        bottom: "3rem",
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    filterInputOp: {
        width: "450px",
        padding: "20px"
    }
}));

const ORDER_OPTIONS = {
    "Title": "title",
    "Created Date": "createdAt"
}

const ObservationsList = ({ entityType }) => {
    const classes = useStyles();
    const { profileId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const [searchStr, setSearchStr] = useState("");
    const [orderFilter, setOrderFilter] = useState("");
    const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
    const [observations, setObservations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [totalPages, setTotalPages] = useState(0)
    const [stateChange, setStateChange] = useState(false)
    const [filterProjects, setFilterProjects] = useState([])
    const [filterProjectsOptions, setFilterProjectsOptions] = useState([])
    const [filterStatus, setFilterStatus] = useState("")

    useEffect(() => {
        getOptionsForObservationsFilter({ profileId: profileId })
            .then((data) => {
                console.log(data)
                setFilterProjectsOptions(data?.projects || [])
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const getTasksForListView = async () => {
        try {
            setLoading(true);
            await getObservations({
                profileId,
                searchStr,
                orderFilter: ORDER_OPTIONS?.[orderFilter],
                orderFilterOrder: orderFilterOrder === "Ascending" ? -1 : 1,
                curPage: (curPage - 1),
                limit: pageLimit,
                status: filterStatus,
                projects: filterProjects ? filterProjects.map((s) => s?._id) : [],
            })
                .then((data) => {
                    if (data) {
                        setObservations(data?.observations || []);
                        let locTotalPage = Math.ceil((data?.totalCount || 0) / pageLimit);
                        setTotalPages(locTotalPage)
                    } else {
                        dispatch({
                            type: "AddApiAlert",
                            payload: {
                                success: false,
                                message: "An unknown error occurred while fetching tasks",
                            },
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: "AddApiAlert",
                        payload: {
                            success: false,
                            message: "An unknown error occurred while fetching tasks",
                        },
                    });
                })
        } catch (err) {
            dispatch({
                type: "AddApiAlert",
                payload: {
                    success: false,
                    message: "An unknown error occurred while fetching tasks",
                },
            });
        } finally {
            setLoading(false);
        }
    };

    useDebounce(() => {
        getTasksForListView();
    }, 1000, [stateChange])

    useEffect(() => {
        setStateChange(!stateChange)
    }, [curPage]);

    useEffect(() => {
        setCurPage(1)
        setStateChange(!stateChange)
    }, [searchStr, orderFilter, orderFilterOrder, filterStatus, filterProjects]);

    return (
        <div className={classes.mainCont} >
            <div className={classes.header} >
                <MyPopOver
                    closeOnClick={false}
                    appearContent={
                        <Button
                            startIcon={<FaFilter />}
                            variant="outlined"
                            color="primary"
                        >
                            Add Filter
                        </Button>
                    }
                    showContent={<div className={classes.filterInputOp} >
                        <Box flex="1" style={{ marginBottom: "15px" }} >
                            <SearchField
                                value={searchStr}
                                onChange={(e) => setSearchStr(e.target.value)}
                                sx={{
                                    backgroundColor: "white",
                                    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                                }}
                                fullWidth={true}
                                size="small"
                            />
                        </Box>
                        {entityType === "Organization" && (
                            <Box flex="1" style={{ marginBottom: "15px" }} >
                                <Autocomplete
                                    options={filterProjectsOptions}
                                    value={filterProjects}
                                    onChange={(event, value) => {
                                        setFilterProjects(value)
                                    }}
                                    fullWidth
                                    multiple={true}
                                    size="small"
                                    getOptionLabel={(option) => option?.displayName}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            {...params}
                                            placeholder={"Select project"}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        <Box flex="1" style={{ marginBottom: "15px" }} >
                            <Select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                displayEmpty
                                size="small"
                                style={{ width: "100%" }}
                            >
                                <MenuItem value="">Filter (None)</MenuItem>
                                {["In Review", "Archived", "Draft"].map((option) => (
                                    <MenuItem value={option}>{option}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box flex="1" >
                            <BoxSpaceBetween>
                                <Select
                                    value={orderFilter}
                                    onChange={(e) => setOrderFilter(e.target.value)}
                                    displayEmpty
                                    size="small"
                                    style={{ width: "200px" }}
                                >
                                    <MenuItem value="">Filter (None)</MenuItem>
                                    {Object.keys(ORDER_OPTIONS).map((option) => (
                                        <MenuItem value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    value={orderFilterOrder}
                                    onChange={(e) => setOrderFilterOrder(e.target.value)}
                                    displayEmpty
                                    size="small"
                                    style={{ width: "200px" }}
                                >
                                    <MenuItem value="Ascending">Ascending</MenuItem>
                                    <MenuItem value="Descending">Descending</MenuItem>
                                </Select>
                            </BoxSpaceBetween>
                        </Box>
                    </div>}
                />
                <div></div>
            </div>
            <div className={classes.bodyCont} >
                {loading ? (<>
                    <Grid container spacing={2}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => (
                            <Grid item xs={12} sm={6} md={4}>
                                <Skeleton variant="rounded" width="100%" height={200} />
                            </Grid>
                        ))}
                    </Grid>
                </>) : (
                    <Grid container spacing={2}>
                        {observations.map((observation) => (
                            <ObservationCard
                                key={observation._id}
                                observation={observation}
                                onClick={() => {
                                    history.push(`/observation/preview/${observation?._id}`)
                                }}
                            />
                        ))}
                    </Grid>
                )}
            </div>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>
        </div>
    );
};

export default ObservationsList;