import React, { useEffect, useState } from "react";
import {
    Box, Grid, MenuItem, Pagination, Skeleton, Drawer
} from "@mui/material";
import { TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import Button from "../styled/generic/Button";
import SearchField from "../styled/generic/SearchField";
import Select from "../styled/generic/Select";
import { getActivityParentList, getFilterOptionsForActivityParent } from "./api.call";
import { useDebounce } from "react-use";
import { FaFilter } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import ActivityCard from "./activity.card";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white"
    },
    header: {
        padding: theme.spacing(1, 2),
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    bodyCont: {
        flexGrow: 1,
        overflowY: "auto",
        padding: theme.spacing(2),
    },
    paginationCont: {
        padding: theme.spacing(1),
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    filterInputOp: {
        width: 450,
        padding: theme.spacing(2),
    },
    drawer: {
        width: 350,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 350,
    },
}));

const ORDER_OPTIONS = {
    "Title": "title",
    "Created Date": "createdAt"
};

const ActivityParentList = ({ parentId, parentModelName, isDrawer = false }) => {
    const classes = useStyles();
    const history = useHistory();

    const [searchStr, setSearchStr] = useState("");
    const [orderFilter, setOrderFilter] = useState("");
    const [orderFilterOrder, setOrderFilterOrder] = useState("Descending");
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [curPage, setCurPage] = useState(1);
    const [pageLimit] = useState(15);
    const [totalPages, setTotalPages] = useState(0);
    const [stateChange, setStateChange] = useState(false);
    const [filterType, setFilterType] = useState("");
    const [filterTypeOptions, setFilterTypeOptions] = useState([]);
    const [filterUserOptions, setFilterUserOptions] = useState([]); // Add this line
    const [filterUser, setFilterUser] = useState(""); // Add this line
    const [filterModule, setFilterModule] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        getFilterOptionsForActivityParent({ parentId, parentModelName })
            .then((data) => {
                setFilterTypeOptions(data?.activityTypes || []);
                setFilterUserOptions(data?.users || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [parentId, parentModelName]);

    const getActivitiesForListView = async () => {
        try {
            setLoading(true);
            const result = await getActivityParentList({
                parentId,
                parentModelName,
                searchStr,
                orderFilter: ORDER_OPTIONS?.[orderFilter] || 'createdAt',
                orderFilterOrder: orderFilterOrder === "Ascending" ? 1 : -1,
                curPage: curPage - 1,
                limit: pageLimit,
                types: filterType ? [filterType] : [],
                modules: filterModule,
            });
            if (result) {
                console.log(JSON.stringify(result.activities),' result.activities');
                setActivities(result.activities || []);
                setTotalCount(result.totalCount || 0);
                setTotalPages(result.totalPages || 0);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useDebounce(() => {
        getActivitiesForListView();
    }, 1000, [stateChange]);

    useEffect(() => {
        setStateChange(!stateChange);
    }, [curPage]);

    useEffect(() => {
        setCurPage(1);
        setStateChange(!stateChange);
    }, [searchStr, orderFilter, orderFilterOrder, filterType,  parentId, parentModelName]);

    const renderContent = () => (
        <div className={classes.mainCont}>
            <div className={classes.header}>
                <MyPopOver
                    closeOnClick={false}
                    appearContent={
                        <Button
                            startIcon={<FaFilter />}
                            variant="outlined"
                            color="primary"
                        >
                            Add Filter
                        </Button>
                    }
                    showContent={
                        <div className={classes.filterInputOp}>
                            <Box flex="1" style={{ marginBottom: "15px" }}>
                                <SearchField
                                    value={searchStr}
                                    onChange={(e) => setSearchStr(e.target.value)}
                                    sx={{
                                        backgroundColor: "white",
                                        boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                                    }}
                                    fullWidth={true}
                                    size="small"
                                />
                            </Box>
                            <Box flex="1" style={{ marginBottom: "15px" }}>
                                <Select
                                    value={filterType}
                                    onChange={(e) => setFilterType(e.target.value)}
                                    displayEmpty
                                    size="small"
                                    style={{ width: "100%" }}
                                >
                                    <MenuItem value="">Filter by Type (None)</MenuItem>
                                    {filterTypeOptions.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>{option._id}</MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            <Box flex="1">
                                <Box display="flex" justifyContent="space-between">
                                    <Select
                                        value={orderFilter}
                                        onChange={(e) => setOrderFilter(e.target.value)}
                                        displayEmpty
                                        size="small"
                                        style={{ width: "200px" }}
                                    >
                                        <MenuItem value="">Filter (None)</MenuItem>
                                        {Object.keys(ORDER_OPTIONS).map((option) => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                    <Select
                                        value={orderFilterOrder}
                                        onChange={(e) => setOrderFilterOrder(e.target.value)}
                                        displayEmpty
                                        size="small"
                                        style={{ width: "200px" }}
                                    >
                                        <MenuItem value="Ascending">Ascending</MenuItem>
                                        <MenuItem value="Descending">Descending</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                        </div>
                    }
                />
            </div>
            <Box className={classes.bodyCont}>
                <Grid container spacing={2}>
                    {loading ? (
                        Array.from(new Array(9)).map((_, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Skeleton variant="rectangular" width="100%" height={160} />
                            </Grid>
                        ))
                    ) : (
                        activities.map((activity) => (
                            <Grid item xs={12} sm={6} md={4} key={activity._id}>
                                <ActivityCard
                                    activity={activity}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Box>
            <div className={classes.paginationCont}>
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value); }}
                />
            </div>
        </div>
    );

    if (isDrawer) {
        return (
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="right"
            >
                {renderContent()}
            </Drawer>
        );
    }

    return renderContent();
};

export default ActivityParentList;