import React, { useEffect, useCallback, useState } from "react";
import StandardContainer from "../../styled/generic/StandardContainer";
import { makeStyles } from "@material-ui/core";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import BugReportIcon from '@mui/icons-material/BugReport';
import emptyFile from "../../../Assets/emptyData.svg"
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  Pagination,
  Select,
  Typography,
  Chip,
  Paper
} from "@mui/material";
import Button from "../../styled/generic/Button";
import HorizBox from "../../styled/generic/HorizBox";
import Api from "../../../helpers/Api";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import StatusChip from "../../styled/generic/StatusChip";
import { getProgressColor } from "../../../helpers/colorHelper";
import SingleUserWithCaptionTableCell from "../../styled/generic/SingleUserWithCaptionTableCell";
import TaskCard from "./components/TaskCardDetail";
import StandardAppContainer from "../../styled/generic/StandardAppContainer";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import { Add } from "@mui/icons-material";
import FormBox from "../../styled/generic/FormBox";
import AddTaskDialog from "./components/AddTaskDialog";
import TaskProgressUpdateDrawer from "./components/TaskProgressUpdateDrawer";
import TabsWithCommonAction from "../../styled/generic/TabsWithCommonAction";
import StandardAppContainerRoundedWithHeader from "../../styled/generic/StandardAppContainerRoundedWithHeader";
import SmartCommentList from "../../comment/smart.comment.list";
import dayjs from "dayjs";
import SearchField from "../../styled/generic/SearchField";
import TableContainer from "../../styled/generic/TableContainer";
import { format } from "timeago.js";
import DocViewer from "../../styled/generic/DocViewer";
import Dialog from "../../styled/generic/Dialog";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";
import BudgetMapping from "../../budgetCostAnalysis/BudgetMapping";
import { styled } from "@mui/material/styles";
import { updateCheckpoint } from "../../OfferingsAndProducts/Api.call";
import arrayToReducer from "../../../helpers/arrayToReducer";
import CheckpointManager from "../../OfferingsAndProducts/CheckpointManager";
import ObservationList from "../../sitereport/ObservationList";
import { addObservationToParent } from "../../sitereport/Api.call";
import DocPickerDrawer from "../../doc/doc.picker.drawer";
import { getBugsByTargetId, getSubTasksByTargetId, getTaskDetailsById, removeDocFromTask } from "../api.call";
import { BiTask } from "react-icons/bi";
import { GiArrowScope } from "react-icons/gi";
import { FaBinoculars } from 'react-icons/fa'
import AllKindOfDocViews from "../../styled/CommonComponents/AllKindOfDocViews";
import DeleteConfirmBox from "../../styled/CommonComponents/DeleteConfirmBox";
import ActivityParentList from "../../activity/ActivityParentList";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getSOPs, getAllChecklists } from "../../OfferingsAndProducts/Api.call";
import ReactHtmlParser from 'react-html-parser';
import { Breadcrumbs, Link } from '@mui/material';
import configObject from '../../../config/index';
import { RiBillLine } from "react-icons/ri";
import TaskBillItems from "./TaskBillItems";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    paddingBottom: "30px",
    overflow: "hidden",
    backgroundColor: "white"
  },
  header: {
    width: "100%",
    padding: "20px 20px 0px",
    borderBottom: "1px solid #ececec",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginBottom: "15px",
      color: "black"
    }
  },
  tabsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
  },
  selectedTab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    opacity: 1,
    flexShrink: 0,
    padding: "15px 5px",
    marginRight: "20px",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "& p": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px"
    },
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "18px"
    },
    "& h4": {
      padding: "0px 5px",
      borderRadius: "8px",
      border: "1px solid gray",
      color: "black"
    },
    transition: "opacity .3s ease-in-out",
  },
  unSelectedTab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    opacity: 0.7,
    flexShrink: 0,
    padding: "15px 5px",
    marginBottom: "2px",
    marginRight: "20px",
    "& p": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
    },
    "& svg": {
      fontSize: "18px"
    },
    "& h4": {
      padding: "0px 5px",
      borderRadius: "8px",
      border: "1px solid gray",
      color: "black"
    },
    transition: "opacity .3s ease-in-out",
  },
  bodyCont: {
    width: "100%",
    padding: "20px 10px"
  },
  emptyCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "200px",
      width: "auto",
      marginBottom: "10px"
    },
    "& p": {
      fontSize: "14px",
      color: "gray"
    }
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red"
    },
    "& h3": {
      fontSize: "20px"
    },
    "& p": {
      fontSize: "14px"
    }
  },
}));

const TabComp = ({
  Icon, title, count, selected, onClick
}) => {
  const classes = useStyles();
  return (
    <div
      className={selected === title ? classes.selectedTab : classes.unSelectedTab}
      onClick={() => onClick(title)}
    >
      <Icon />
      <p>{title}</p>
      {/* <h4>{count}</h4> */}
    </div>
  )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const SOPItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const ORDER_OPTIONS = ["DATE CREATED", "PROGRESS", "START DATE"];

const TaskDetails = () => {
  const classes = useStyles();
  const { taskId } = useParams();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState();
  const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
  const [target, setTarget] = useState();
  const [subTasks, setSubTasks] = useState([]);
  const [showUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const [tab, setTab] = useState(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('tab') || 'Documents';
  });
  const [searchStr, setSearchStr] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [bugs, setBugs] = useState([]);
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [showProgressHistoryDialog, setShowProgressHistoryDialog] = useState(false);
  const [selectedProgressHistory, setSelectedProgressHistory] = useState();
  const [selectedProgressHistoryFiles, setSelectedProgressHistoryFiles] = useState([]);
  const [pageNumberST, setPageNumberST] = useState(1);
  const [totalPagesST, setTotalPagesST] = useState(1);
  const [pageNumberB, setPageNumberB] = useState(1);
  const [totalPagesB, setTotalPagesB] = useState(1);
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [checkpointDict, setCheckpointDict] = useState({});
  const [checkpointIds, setCheckpointIds] = useState([]);
  const [showDocPicker, setShowDocPicker] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [formatedDocFiles, setFormatedDocFiles] = useState([])
  const [removeAbleFiles, setRemoveAbleFiles] = useState([])
  const [docDeleteOpen, setDocDeleteOpen] = useState(false)
  const [deleteDocLoader, setDeleteDocLoader] = useState(false)
  const [checklists, setChecklists] = useState([]);
  const [sops, setSOPs] = useState([]);
  const [selectedSOPs, setSelectedSOPs] = useState([]);
  const [loadingSOPs, setLoadingSOPs] = useState(false);

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  const shareBtn = (
    <Button variant="outlined" color="primary">
      Manage Resource Access
    </Button>
  )

  const handleTabChange = (tabName) => {
    setTab(tabName);
    history.push({
      pathname: location.pathname,
      search: `?tab=${tabName}`,
    });
  };




  const {
    sharedProfilesRole, ShareButton, ShareDrawer, SharedView,
    assignedProfiles, AssignButton, AssigneDrawer, AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole, initAssigned: null,
    resourceId: taskId, resourceName: 'WBS',
    customShareBtn: shareBtn, customAssignBtn: assignBtn,
  });

  useEffect(() => {
    if (task?.checkpoints) {
      const { newDict, idArr } = arrayToReducer(task.checkpoints);
      console.log(task.checkpoints, ' task.checkpoints ')
      setCheckpointDict(newDict);
      setCheckpointIds(idArr);
    }
  }, [task?.checkpoints]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentTab = queryParams.get('tab') || 'Documents';
    if (currentTab !== tab) {
      setTab(currentTab);
    }
  }, [location.search]);

  const handleDocSelection = (docs) => {
    if (docs) {
      setSelectedDocs(docs);
    }
  };

  const getTarget = async () => {
    try {
      setLoading(true);
      await getTaskDetailsById({ wbsId: taskId })
        .then((data) => {
          console.log(data)
          if (data) {
            setTask(data);
            setTarget(data?.target);
            setSelectedDocs(data?.docs || [])
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred while fetching target details",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching target details",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getSubTasks = async () => {
    try {
      setLoading(true);
      await getSubTasksByTargetId({
        taskId: taskId,
        enablePagination: true,
        pageNumber: pageNumberST,
      })
        .then((data) => {
          if (data) {
            setSubTasks(data?.subTasks);
            setTotalPagesST(data?.totalPages);
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred while fetching subtasks",
              },
            });
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching subtasks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getBugs = async () => {
    try {
      setLoading(true);
      await getBugsByTargetId({
        taskId: taskId,
        pageNumber: pageNumberB,
      })
        .then((data) => {
          if (data) {
            setBugs(data?.bugs);
            setTotalPagesB(data?.totalPages);
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred while fetching bugs",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching bugs",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTarget();
    getSubTasks();
    getBugs();
  }, [taskId]);

  useEffect(() => {
    getSubTasks();
  }, [pageNumberST]);

  useEffect(() => {
    getBugs();
  }, [pageNumberB]);

  useEffect(() => {
    let _docs = selectedProgressHistory?.docs || [];
    let _files = [];
    for (let i = 0; i < _docs.length; i++) {
      let _doc = _docs[i];
      let _docFiles = _doc?.files || [];
      for (let j = 0; j < _docFiles.length; j++) {
        let _docFile = _docFiles[j];
        if (!_docFile) {
          continue;
        }
        _files.push(_docFile);
      }
    }

    setSelectedProgressHistoryFiles(_files);
  }, [selectedProgressHistory]);

  const handleCreateObservation = async () => {
    try {
      const response = await addObservationToParent({
        parentId: taskId,
        parentModelName: 'WBS',
        profileId: task?.project?.profile ? task?.project?.profile : task?.organization?.profile,
        userProfile: user?.profile,
        observationData: {
          user: user?._id,
          userProfile: user?.profile,
          project: task?.project?._id,
          organization: task?.organization?._id,
          tags: [
            { data: taskId, dataModel: 'WBS' }
          ],
        }
      });
      return response?.newObservation;
    } catch (error) {
      console.error("Error creating observation:", error);
      throw error;
    }
  };

  function getLibraryId(library) {
    if (typeof library === 'object' && library !== null) {
      return library?._id;
    } else if (typeof library === 'string') {
      return library;
    }
    return null;
  }

  useEffect(() => {
    let arrData = [];
    selectedDocs.map((docData) => {
      const docFile = docData?.files || []
      docFile.map((fileData, index) => {
        if (fileData?.url) {
          arrData.push({
            ...fileData,
            numDocFile: docFile.length,
            documentName: docData?.title,
            name: (docFile && docFile.length > 1) ? `${docData?.title || ""}-(${index + 1})` : docData?.title || "",
            docId: docData?._id
          })
        }
      })
    })
    setFormatedDocFiles(arrData)
  }, [selectedDocs])

  const removeDocuments = async (fileId) => {
    const file = formatedDocFiles.filter((s) => s?._id === fileId)[0]
    const filesGoingToRemove = formatedDocFiles.filter((s) => s?.docId === file?.docId)
    setRemoveAbleFiles(filesGoingToRemove)
    setDocDeleteOpen(true)
  }

  const finalDocRemove = async (docId) => {
    setDeleteDocLoader(true)
    await removeDocFromTask({
      taskId,
      docId
    })
      .then((data) => {
        console.log(data)
        setSelectedDocs(selectedDocs.filter((s) => s?._id != docId))
        setRemoveAbleFiles([])
        setDocDeleteOpen(false)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setDeleteDocLoader(false)
      })
  }

  const fetchSOPs = async () => {
    try {
      const res = await getSOPs({
        libraryId: getLibraryId(task?.library || task?.target?.buildingCodeLibrary),
      });
      let data = res?.data;
      console.log(data, ' is fetchsops')
      if (Array.isArray(data)) {
        setSOPs(data);
      }
    } catch (error) {
      console.error("Error fetching SOPs:", error);
    }
  };

  const fetchChecklists = async () => {
    try {
      const res = await getAllChecklists({
        libraryId: getLibraryId(task?.library || task?.target?.buildingCodeLibrary),
      });
      if (Array.isArray(res?.data)) {
        setChecklists(res.data);
      }
    } catch (error) {
      console.error("Error fetching checklists:", error);
    }
  };

  useEffect(() => {
    if (task?.library || task?.target) {
      fetchSOPs();
      fetchChecklists();
    }
  }, [task?.library, task?.target]);

  const handleSOPChange = async (event) => {
    const newSOPIds = event.target.value;
    const newSOPs = sops.filter(sop => newSOPIds.includes(sop._id));
    setSelectedSOPs(newSOPs);

    if (taskId) {
      try {
        setLoadingSOPs(true);
        const response = await Api.post("/wbs/update-custom", {
          _id: taskId,
          updateBody: {
            sops: newSOPIds,
          },
        });

        if (response.data) {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "Task SOPs updated successfully",
            },
          });
          // Update the task state with the new data
          setTask(prevTask => ({
            ...prevTask,
            sops: newSOPs
          }));
        } else {
          throw new Error("Failed to update task SOPs");
        }
      } catch (error) {
        console.error("Error updating task SOPs:", error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An error occurred while updating task SOPs",
          },
        });
      } finally {
        setLoadingSOPs(false);
      }
    }
  };

  console.log(task)

  return (
    <StandardContainer
      title="Task Details"
      showAppBar={true}
      appBarActions={
        <>
          <BudgetMapping
            projectProfileId={task?.project?.profile}
            dataId={task?._id}
            dataModel={"WBS"}
          />
          <span style={{ marginRight: "10px" }} ></span>
          {ShareButton}
          {ShareDrawer}
          <Button
            variant="outlined"
            onClick={() => setShowDocPicker(true)}
            startIcon={<Add />}
            style={{ marginLeft: "10px" }}
          >
            Docs
          </Button>
        </>
      }
      loading={loading}
      appBarTitle={task?.taskName || "Untitled"}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator=">"
        className={classes.breadcrumbs}
      >
        {task?.organization && (
          <Link
            color="inherit"
            onClick={() => {
              const url = `${configObject.mode === 'prod' ? 'https://reallist.ai' : ''}/organization/${task.organization.team}`;
              configObject.mode === 'prod'
                ? window.open(url, "_self")
                : history.push(url);
            }}
            style={{ cursor: 'pointer' }}
          >
            {task.organization.name || 'Organization'}
          </Link>
        )}
        {task?.project && (
          <Link
            color="inherit"
            onClick={() => {
              const url = `${configObject.mode === 'prod' ? 'https://reallist.ai' : ''}/project/${task.project.team}`;
              configObject.mode === 'prod'
                ? window.open(url, "_self")
                : history.push(url);
            }}
            style={{ cursor: 'pointer' }}
          >
            {task.project.name || 'Project'}
          </Link>
        )}
        {task?.target && (
          <Link
            color="inherit"
            onClick={() => {
              const url = `${configObject.mode === 'prod' ? 'https://planning.reallist.ai' : ''}/planning/target/${task.target._id}`;
              configObject.mode === 'prod'
                ? window.open(url, "_self")
                : history.push(url);
            }}
            style={{ cursor: 'pointer' }}
          >
            {task.target.title || 'Target'}
          </Link>
        )}
        <Typography color="textPrimary">
          {task?.taskName || "Untitled"}
        </Typography>
      </Breadcrumbs>

      <StandardAppContainerRoundedWithHeader
        title="Task Details"
        icon=""
        padding={1}
      >
        <TaskCard
          fullTaskView={true}
          task={task}
          setTask={setTask}
          onTaskUpdate={setTask}
          disableBorder={true}
          disableElevation={true}
        />
      </StandardAppContainerRoundedWithHeader>

      <div className={classes.mainCont} >
        <div className={classes.header} >
          <h3>Linked Items</h3>
          <div className={classes.tabsCont} >
            <TabComp
              Icon={FileCopyIcon}
              title={"Documents"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={GiArrowScope}
              title={"SOPs"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={TaskAltIcon}
              title={"Checkpoints"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={FaBinoculars}
              title={"Observations"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={RiBillLine}
              title={"Bill Items"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={BiTask}
              title={"Sub Tasks"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={HistoryToggleOffIcon}
              title={"Progress History"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={HistoryToggleOffIcon}
              title="Activities"
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={MarkUnreadChatAltIcon}
              title={"Comments"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
            <TabComp
              Icon={BugReportIcon}
              title={"Bugs"}
              count={0}
              selected={tab}
              onClick={handleTabChange}
            />
          </div>
        </div>
        <div className={classes.bodyCont} >
          {tab === "Documents" && (
            <>
              {formatedDocFiles && formatedDocFiles.length > 0 ? (<>
                <AllKindOfDocViews
                  files={formatedDocFiles}
                  removeFun={removeDocuments}
                  isDeletable={true}
                />
                <DeleteConfirmBox
                  open={docDeleteOpen}
                  setOpen={setDocDeleteOpen}
                  loading={deleteDocLoader}
                  pageTitle={"Remove Document"}
                  cantent={
                    <div className={classes.deleteConfirm} >
                      <ReportProblemIcon />
                      <h3>Are you sure?</h3>
                      <p>Below files going to be remove form this task</p>
                      <ol>
                        {removeAbleFiles && removeAbleFiles.map((file, i) => (<li>{i + 1}. {file?.name}</li>))}
                      </ol>
                    </div>
                  }
                  cancleConfirmDelete={() => {
                    setDocDeleteOpen(false)
                    setRemoveAbleFiles([])
                  }}
                  confirmDelete={() => {
                    finalDocRemove(removeAbleFiles[0]?.docId)
                  }}
                />
              </>) : (<div className={classes.emptyCont} >
                <img src={emptyFile} alt="empty image" />
                <p>No attachment available. Click above button to add attachment(s)</p>
              </div>)}
            </>
          )}

          {tab === "SOPs" && (
            <StyledPaper elevation={0}>
              <Typography variant="h6" gutterBottom>Standard Operating Procedures</Typography>
              <FormBox label="Select SOPs">
                <Select
                  fullWidth
                  multiple
                  value={selectedSOPs.map(sop => sop._id)}
                  onChange={handleSOPChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {selected.map((value) => {
                        const selectedItem = sops.find(sop => sop._id === value);
                        return (
                          <Chip
                            key={value}
                            label={selectedItem?.title || "Unknown"}
                            onDelete={() => {
                              const newSOPs = selectedSOPs.filter(sop => sop._id !== value);
                              setSelectedSOPs(newSOPs);
                              handleSOPChange({ target: { value: newSOPs.map(sop => sop._id) } });
                            }}
                          />
                        );
                      })}
                    </Box>
                  )}
                  disabled={loadingSOPs}
                >
                  {sops.map((sop) => (
                    <MenuItem key={sop._id} value={sop._id}>
                      {sop.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
              <Box sx={{ mt: 2 }}>
                {selectedSOPs.map((sop, index) => (
                  <Box key={sop._id} sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {index + 1}. {sop.title}
                    </Typography>
                    <Typography variant="body2">{sop.description}</Typography>
                    <Box sx={{ mt: 1, mb: 1 }}>
                      {ReactHtmlParser(sop.content)}
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <Chip
                        label={`Version: ${sop.version}`}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      <Chip
                        label={`Status: ${sop.status}`}
                        size="small"
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </StyledPaper>
          )}

          {tab === "Checkpoints" && (
            <StyledPaper elevation={0}>
              <CheckpointManager
                checkpointIds={checkpointIds}
                checkpointDict={checkpointDict}
                setCheckpointIds={setCheckpointIds}
                setCheckpointDict={setCheckpointDict}
                libraryId={getLibraryId(task?.library) || task?.target?.buildingCodeLibrary}
                parentId={task?._id}
                profileId={task?.project?.profile ? task?.project?.profile : task?.organization?.profile}
                parentModelName="WBS"
                checklists={checklists}
              />
            </StyledPaper>
          )}

          {tab === "Observations" && (
            <StyledPaper elevation={0}>
              <ObservationList
                queryProps={{
                  parent: task?._id,
                  parentModelName: "WBS",
                  tags: [{ data: task?._id, dataModel: 'WBS' }]
                }}
                drawerMode={false}
                profileId={task?.project?.profile ? task?.project?.profile : task?.organization?.profile}
                onObservationCreate={handleCreateObservation}
                onCardClick={(observation) => {
                  console.log("Clicked observation:", observation);
                }}
              />
            </StyledPaper>
          )}

          {tab === "Sub Tasks" && (
            <StandardAppContainerRounded>
              <BoxSpaceBetween>
                <Box flex="0.5">
                  <SearchField
                    value={searchStr}
                    onChange={(e) => setSearchStr(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                    }}
                    fullWidth={true}
                    size="small"
                  />
                </Box>
                <HorizBox>
                  <Select
                    value={orderFilter}
                    onChange={(e) => setOrderFilter(e.target.value)}
                    displayEmpty
                    size="small"
                  >
                    <MenuItem value="">Filter (None)</MenuItem>
                    {ORDER_OPTIONS.map((option) => (
                      <MenuItem value={option}>{option}</MenuItem>
                    ))}
                  </Select>

                  <Select
                    value={orderFilterOrder}
                    onChange={(e) => setOrderFilterOrder(e.target.value)}
                    displayEmpty
                    size="small"
                  >
                    <MenuItem value="Ascending">Ascending</MenuItem>
                    <MenuItem value="Descending">Descending</MenuItem>
                  </Select>

                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => {
                      setShowAddTaskDialog(true);
                    }}
                  >
                    New
                  </Button>
                </HorizBox>
              </BoxSpaceBetween>
              {subTasks?.length > 0 ? (
                <Box>
                  {subTasks.map((subTask) => (
                    <BoxSpaceBetween
                      mt={2}
                      sx={{
                        backgroundColor: "#F3F3F3",
                        borderRadius: "10px",
                        cursor: "pointer",
                        p: 2,
                      }}
                      onClick={() => {
                        history.push(`/planning/task/${subTask?._id}`);
                      }}
                    >
                      <HorizBox>
                        <Box
                          sx={{
                            backgroundColor: getProgressColor(subTask?.progress)
                              ?.backgroundColor,
                            p: 1,
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              color: getProgressColor(subTask?.progress)?.color,
                            }}
                          >
                            {subTask?.progress || 0}%
                          </Typography>
                        </Box>

                        <Box>
                          <Typography variant="subtitle2">
                            {subTask?.taskName}
                          </Typography>
                          <Typography variant="body2">
                            {subTask?.description || "No description"}
                          </Typography>
                        </Box>
                      </HorizBox>
                      <Box>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            setTaskToUpdate(subTask);
                            setShowUpdateTaskDialog(true);
                          }}
                        >
                          Update
                        </Button>
                      </Box>
                    </BoxSpaceBetween>
                  ))}
                </Box>
              ) : (
                <Box sx={{ p: 1 }}>No sub tasks found</Box>
              )}
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Pagination
                  count={totalPagesST}
                  page={pageNumberST}
                  onChange={(e, page) => setPageNumberST(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </StandardAppContainerRounded>
          )}

          {tab === "Progress History" && (
            <>
              <StandardAppContainerRounded>
                <Box>
                  {task?.progressHistory?.length > 0 ? (
                    <Box>
                      {task?.progressHistory
                        ?.filter((item) => item !== null)
                        .map((progress, index) => (
                          <Box
                            mt={2}
                            sx={{
                              backgroundColor: "#F3F3F3",
                              borderRadius: "10px",
                              cursor: "pointer",
                              p: 2,
                            }}
                            onClick={() => {
                              setSelectedProgressHistory(progress);
                              setShowProgressHistoryDialog(true);
                            }}
                          >
                            <BoxSpaceBetween>
                              <Box>
                                <Typography variant="subtitle1">
                                  PROGRESS UPDATE
                                </Typography>
                                <Typography variant="subtitle2">
                                  {dayjs(progress?.createdAt).format(
                                    "DD MMM YYYY, hh:mm a"
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  width: "100px",
                                  backgroundColor: getProgressColor(
                                    progress?.progress
                                  )?.backgroundColor,
                                  color: getProgressColor(progress?.progress)
                                    ?.color,
                                  borderRadius: "10px",
                                  display: "flex",
                                  alignItems: "flex-end",
                                  justifyContent: "center",
                                  p: 1,
                                  flexDirection: "column",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "1rem",
                                  }}
                                >
                                  Work Done
                                </span>
                                <span>{progress?.progress}/100</span>
                              </Box>
                            </BoxSpaceBetween>
                          </Box>
                        ))}
                    </Box>
                  ) : (
                    <Box sx={{ p: 1 }}>No progress history found</Box>
                  )}
                </Box>
              </StandardAppContainerRounded>
              <Dialog
                open={showProgressHistoryDialog}
                setOpen={setShowProgressHistoryDialog}
                title="Progress Update"
              >
                <Box>
                  <FormBox label="Task">
                    <Typography variant="subtitle1">
                      {task?.taskName || "Untitled"}
                    </Typography>
                  </FormBox>
                  <FormBox label="Comments">
                    <Typography variant="body1">
                      {selectedProgressHistory?.comments || "No comments"}
                    </Typography>
                  </FormBox>
                  <FormBox label="Work Done">
                    <Typography variant="body1">
                      {selectedProgressHistory?.progress || 0}%
                    </Typography>
                  </FormBox>
                  <FormBox label="Work Left">
                    <Typography variant="body1">
                      {100 - (selectedProgressHistory?.progress || 0)}%
                    </Typography>
                  </FormBox>
                  <FormBox label="Date">
                    <Typography variant="body1">
                      {dayjs(selectedProgressHistory?.createdAt).format(
                        "DD MMM YYYY, hh:mm a"
                      )}
                    </Typography>
                  </FormBox>
                  <FormBox label="Documents">
                    <DocViewer
                      data={selectedProgressHistoryFiles}
                      isDeletable={false}
                      disableEditMetaData={true}
                      disableShowMetaData={true}
                      onMetadataChange={() => { }}
                      deleteFunction={() => { }}
                    />
                  </FormBox>
                </Box>
              </Dialog>
            </>
          )}

          {tab === "Activities" && (
            <StandardAppContainerRounded>
              <ActivityParentList
                parentId={taskId}
                parentModelName="WBS"
              />
            </StandardAppContainerRounded>
          )}

          {tab === "Comments" && (
            <StandardAppContainerRounded>
              <SmartCommentList parent={task?._id} parentModelName="WBS" />
            </StandardAppContainerRounded>
          )}

          {tab === "Bugs" && (
            <StandardAppContainerRounded>
              <TableContainer
                columns={["TITLE", "LABELS", "PRIORITY", "ASSIGNEES"]}
                data={bugs}
                loading={loading}
              >
                {bugs.map((bug, index) => (
                  <tr
                    onClick={() => {
                      history.push(`/bugs/bug/${bug?._id}`);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <td>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: "bold",
                          }}
                        >
                          {bug?.title} #{bug?.count}{" "}
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          #{bug?.count}{" "}
                          {bug?.type === "Open" ? "opened" : "closed"}{" "}
                          {bug?.type === "Open"
                            ? format(bug?.openDate)
                            : format(bug?.closeDate)}{" "}
                          by {bug?.user?.displayName}
                        </Typography>
                      </Box>
                    </td>
                    <td>
                      {bug?.labels?.length > 0 ? (
                        <Box>
                          {bug?.labels?.slice(0, 3)?.map((label) => (
                            <div
                              style={{
                                backgroundColor: label?.color + "33",
                                color: label?.color,
                                padding: "5px",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "5px",
                                fontSize: "0.8rem",
                                borderRadius: "8px",
                                margin: "5px",
                              }}
                            >
                              {label?.title || "Untitled"}
                            </div>
                          ))}
                        </Box>
                      ) : (
                        <Typography variant="subtitle2" color="textSecondary">
                          No labels
                        </Typography>
                      )}
                    </td>
                    <td>{bug?.priority}</td>
                    <td>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <AvatarGroup max={4}>
                          {bug?.assigned?.map((item) => (
                            <Avatar
                              alt={item?.parent?.displayName}
                              src={item?.parent?.displayPicture?.url}
                              sx={{
                                width: "32px",
                                height: "32px",
                              }}
                            />
                          ))}
                        </AvatarGroup>
                      </Box>
                    </td>
                  </tr>
                ))}
              </TableContainer>
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Pagination
                  count={totalPagesB}
                  page={pageNumberB}
                  onChange={(e, page) => setPageNumberB(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </StandardAppContainerRounded>
          )}

          {tab === "Bill Items" && (
            <TaskBillItems
              task={task}
              setTask={setTask}
              setLoading={setLoading}
              loading={loading}
              wallet={task?.project?.wallet ? task?.project?.wallet : task?.organization?.wallet}
              profileId={task?.project?.profile ? task?.project?.profile : task?.organization?.profile}
              needToMakeDisable={false}
            />
          )}
        </div>
      </div>
      <AddTaskDialog
        assigneesByCreater={task?.assigned}
        showAddTaskDialog={showAddTaskDialog}
        setShowAddTaskDialog={setShowAddTaskDialog}
        targetId={target?._id}
        onTaskAdd={(newTask) => {
          history.push(`/planning/task/${newTask._id}`);
        }}
        profileId={task?.project?.profile ? task?.project?.profile : task?.organization?.profile}
        library={target?.buildingCodeLibrary}
        project={target?.project?._id}
        organization={target?.organization}
        parentTaskId={task?._id}
        onTaskUpdate={setTask}
        taskToUpdate={task}
        onTaskDelete={(deletedTask) => {
          history.goBack()
        }}
      />
      <TaskProgressUpdateDrawer
        open={showUpdateTaskDialog}
        setOpen={setShowUpdateTaskDialog}
        setTasks={setSubTasks}
        taskToUpdate={taskToUpdate}
      />
      <DocPickerDrawer
        open={showDocPicker}
        setOpen={setShowDocPicker}
        parentId={task?._id}
        parentModelName={'WBS'}
        onClose={(docs) => {
          setShowDocPicker(false)
          handleDocSelection(docs)
        }}
        initialProfileId={task?.project?.profile || task?.organization?.profile || user?.profile}
        initialModelName={task?.project?._id ? "Project" : (task?.organization?._id ? "Organization" : 'User')}
      />
    </StandardContainer>
  );
};

export default TaskDetails;
