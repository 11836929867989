import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BillListEdit from "../../finance2o/commonComponent/Bill.Item.Table/Bill.List.Edit";
import "react-quill/dist/quill.snow.css";
import { Button, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { addBillListWithTask, updateTaskById } from "../api.call";
import BudgetMapping from "../../budgetCostAnalysis/BudgetMapping";
import { useDebounce } from "react-use";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        overflow: "hidden",
        padding: "10px",
        backgroundColor: "white",
    },
    paperHeader: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
        "& h2": {
            fontSize: "18px",
            fontWeight: "600",
        },
    },
    emptyCont: {
        width: "100%",
        padding: "80px 0px 40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "& p": {
            marginBottom: "10px"
        }
    }
}));

const TaskBillItems = ({
    task,
    setTask,
    wallet,
    profileId,
    needToMakeDisable,
    setLoading,
    loading
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector((state) => state.auth);

    const [billListError, setBillListError] = useState(false);
    const [needToReloadBillList, setNeedToReloadBillList] = useState(false);
    const [billListTotals, setBillListTotals] = useState({
        taxExclTotalAmount: 0,
        taxInclTotalAmount: 0,
        totalTaxAmount: 0,
    });

    const addBillListToTask = async () => {
        setLoading(true)
        await addBillListWithTask({ taskId: task?._id })
            .then((data) => {
                console.log(data)
                setTask(data)
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useDebounce(() => {
        if (billListTotals?.taxInclTotalAmount !== task?.linkedAmount) {
            updateTaskById({
                taskId: task?._id,
                updateObj: {
                    linkedAmount: billListTotals?.taxInclTotalAmount
                }
            })
                .then((data) => {
                    console.log(data)
                    setTask(data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, 1000, [billListTotals?.taxInclTotalAmount])


    return (
        <>
            {task?.billList ? (
                <div className={classes.root} >
                    <div className={classes.paperHeader}>
                        <h2>Edit Items</h2>

                        <BudgetMapping
                            projectProfileId={profileId}
                            dataId={task?.billList}
                            dataModel={"BillList"}
                        />
                    </div>
                    {task?.project?._id && task?.billList && (
                        <BillListEdit
                            billListId={task?.billList}
                            wallet={wallet}
                            projectProfileId={profileId}
                            needToMakeDisable={needToMakeDisable}
                            setBillListError={setBillListError}
                            setBillListTotals={setBillListTotals}
                            billListTotals={billListTotals}
                            setNeedToReloadBillList={setNeedToReloadBillList}
                        />
                    )}
                </div>
            ) : (<div className={classes.emptyCont} >
                <p>Task don't have any bill item, click below button to create bill item</p>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    disabled={loading}
                    startIcon={<AddIcon />}
                    onClick={addBillListToTask}
                >
                    Create Bill Item
                </Button>
            </div>)}
        </>
    );
};

export default TaskBillItems;