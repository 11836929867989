import React, { useEffect, useState } from "react";
import {
    Grid, LinearProgress, Typography, IconButton
} from "@mui/material";
import { Add, EditOutlined } from "@material-ui/icons";
import HorizBox from "../styled/generic/HorizBox.jsx";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween.jsx";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import StandardAppContainerRoundedWithHeader from "../styled/generic/StandardAppContainerRoundedWithHeader.jsx";
import Divider from "../styled/generic/Divider.jsx";
import StatusChip from "../styled/generic/StatusChip.jsx";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell.jsx";
import { GiArrowScope } from "react-icons/gi";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: "white"
    },
    header: {
        width: "100%",
        height: "50px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        padding: "0px 20px"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 100px)",
        display: "flex",
        flexWrap: "wrap",
        overflowY: "auto",
        padding: "20px",
        alignContent: "flex-start"
    },
    tasksNumCont: {
        display: "flex",
        width: "36px",
        height: "36px",
        padding: "8px 9px",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "32px",
        backgroundColor: "#DBEDFF",
        color: "#2D76E0",
    }
}));

const TargetCard = ({
    target, setTargetToUpdate, setOpenCreateTargetDrawer
}) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [completionPercentage, setCompletionPercentage] = useState()

    useEffect(() => {
        if (target?.totalTasks > 0) {
            setCompletionPercentage(Math.round((target?.completedTasks / target?.totalTasks) * 100));
        }
    }, [target])

    return (
        <Grid item xs={12} md={6} lg={4}>
            <StandardAppContainerRoundedWithHeader
                icon={<GiArrowScope style={{ fontSize: "25px" }} />}
                title={target?.title || "Untitled"}
                actions={
                    <HorizBox>
                        <span
                            style={{
                                color: "#717171",
                                fontSize: "18px",
                                fontWeight: 500,
                            }}
                        >
                            tasks
                        </span>
                        <div className={classes.tasksNumCont} >
                            {target?.totalTasks || 0}
                        </div>
                        <IconButton
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                setTargetToUpdate(target);
                                setOpenCreateTargetDrawer(true);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </HorizBox>
                }
                headerTypographyVariant={"subtitle1"}
                headerTypographySx={{ fontWeight: "bold" }}
                onClick={() => history.push(`/planning/target/${target?._id}`)}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        transform: "scale(1.01)",
                    },
                }}
            >
                <BoxSpaceBetween sx={{ mb: 1 }}>
                    <Typography variant="body1">
                        Completion Percentage
                    </Typography>
                    <Typography variant="body1">
                        {completionPercentage}%
                    </Typography>
                </BoxSpaceBetween>
                <LinearProgress
                    variant="determinate"
                    value={completionPercentage}
                />
                <BoxSpaceBetween sx={{ mt: 1 }}>
                    <Typography variant="body1">Project</Typography>
                    <Typography variant="body1">
                        {target?.project?.displayName || "-"}
                    </Typography>
                </BoxSpaceBetween>
                <BoxSpaceBetween sx={{ mt: 1 }}>
                    <Typography variant="body1">Organization</Typography>
                    <Typography variant="body1">
                        {target?.organization?.displayName || "-"}
                    </Typography>
                </BoxSpaceBetween>
                <Divider />
                <BoxSpaceBetween>
                    <StatusChip
                        label={`${dayjs(target?.startDate).format("DD MMM YY") + " • " + dayjs(target?.endDate).format("DD MMM YY")}`}
                        variant="Info"
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                            >
                                <path
                                    d="M15.1693 7.9987C15.1693 11.6787 12.1826 14.6654 8.5026 14.6654C4.8226 14.6654 1.83594 11.6787 1.83594 7.9987C1.83594 4.3187 4.8226 1.33203 8.5026 1.33203C12.1826 1.33203 15.1693 4.3187 15.1693 7.9987Z"
                                    stroke="#717171"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M10.9778 10.1211L8.91115 8.88781C8.55115 8.67448 8.25781 8.16115 8.25781 7.74115V5.00781"
                                    stroke="#717171"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        }
                    />
                    <SingleUserWithCaptionTableCell
                        label={target?.creator?.parent?.displayName}
                        src={target?.creator?.parent?.displayPicture?.url}
                        avatarSx={{
                            width: "25px",
                            height: "25px",
                        }}
                        avatarTextGap={1}
                    />
                </BoxSpaceBetween>
            </StandardAppContainerRoundedWithHeader>
        </Grid>
    );
};

export default TargetCard;