import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DocSvg from "../../../Assets/FileIcon/docIcon.svg";
import PdfIcon from "../../../Assets/FileIcon/pdfIcon.svg";
import XlsIcon from "../../../Assets/FileIcon/excelIcon.svg";
import PptIcon from "../../../Assets/FileIcon/pptIcon.svg";
import CsvIcon from "../../../Assets/FileIcon/csvIcon.svg";
import HtmlIcon from "../../../Assets/FileIcon/htmlIcon.svg";
import RtfIcon from "../../../Assets/FileIcon/rtfIcon.svg";
import TxtIcon from "../../../Assets/FileIcon/txtIcon.svg";
import CancelIcon from '@material-ui/icons/Cancel';
import LessText from "../CommonComponents/LessText";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
    root: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        margin: "0.5rem",
        width: "300px",
        height: "50px",
        padding: "0px 15px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "none",
        border: "1px solid #E7E7ED"
    },
    imgStyle: {
        width: "17px",
        height: "auto"
    },
    textRow: {
        flex: 1,
        flexDirection: "row",
        display: "flex",
        fontSize: "14px",
        textWrap: "wrap",
        fontWeight: "600",
        marginLeft: "1rem",
        marginRight: "1rem",
    },
    deleteIcon: {
        position: "absolute",
        right: "0px",
        cursor: "pointer",
    }
});

const DownloadDocCard = ({ file, isDeletable, removeFun }) => {
    const classes = useStyles();
    const [fileType, setFileType] = useState(DocSvg)

    useEffect(() => {
        let fileType = file?.type.toLowerCase()
        console.log(fileType)
        let FileIcon = DocSvg;
        if (fileType.includes("pdf")) {
            FileIcon = PdfIcon;
        } else if (fileType.includes("xls") || fileType.includes("xlsx")) {
            FileIcon = XlsIcon;
        } else if (fileType.includes("ppt") || fileType.includes("pptx")) {
            FileIcon = PptIcon;
        } else if (fileType.includes("plain")) {
            FileIcon = TxtIcon;
        } else if (fileType.includes("rtf")) {
            FileIcon = RtfIcon;
        } else if (fileType.includes("csv")) {
            FileIcon = CsvIcon;
        } else if (fileType.includes("html") || fileType.includes("xhtml")) {
            FileIcon = HtmlIcon;
        } else {
            FileIcon = DocSvg;
        }
        setFileType(FileIcon)
    }, [file?.type])

    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    const convertBase64 = async () => {
        let url = file?.url;
        if (!url) {
            console.error("URL is not valid");
            return;
        }
        try {
            let blob = await fetch(url).then((r) => r.ok ? r.blob() : Promise.reject("Failed to fetch the file"));
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                console.log(base64data); // Use base64data here
            };
        } catch (error) {
            console.error("Error fetching the file: ", error);
        }
    };

    useEffect(() => {
        const handleConvertBase64 = async () => {
            await convertBase64();
        };
        handleConvertBase64();
    }, [file]);

    return (
        <div style={{ position: "relative" }} >
            {isDeletable && (
                <Tooltip title="Delete" placement="top">
                    <CancelIcon className={classes.deleteIcon}
                        onClick={() => {
                            removeFun(file?._id)
                        }}
                    />
                </Tooltip>
            )}
            <Paper
                className={classes.root}
                onClick={() => {
                    if (file?.url) {
                        openInNewTab(file.url);
                    }
                }}
            >
                <img src={fileType} className={classes.imgStyle} />
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: "500"
                    }}
                >
                    <h3>{file?.documentName}</h3>
                    <Typography variant="body2" className={classes.textRow}>
                        <LessText limit={22} string={file?.name} />
                    </Typography>
                </div>
            </Paper>
        </div>
    );
};



export default DownloadDocCard;
