import React, { useState } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useHistory } from "react-router-dom";
import ApprovalActionDrawer from "./ApprovalActionDrawer";
import { Button } from "@material-ui/core";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import DisscusionPagination from "./DisscusionPagination";

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "60vh",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px",
        },
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        height: "calc(100% - 55px)",
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        borderTop: "1px solid #ececec",
    },
    titleSty: {
        fontSize: "20px",
        fontWeight: "550",
        marginTop: "20px"
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Button);

export default function OnPageApprovalActions({
    parent, parentModelName, requestId, needToMakeDisable, activeStage
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const [openAction, setOpenAction] = useState();
    const [currPage, setCurrPage] = useState(0);
    const [changePageState, setChangePageState] = useState(false);
    const [openApprovalFlow, setOpenApprovalFlow] = useState(true);
    const [loadingBool, setLoadingBool] = useState(false);

    return (<>
        <h3 className={classes.titleSty} >Approval Flow</h3>
        <div className={classes.mainDialogCont} >
            <div className={classes.bodyCont} >
                <DisscusionPagination
                    openApprovalFlow={openApprovalFlow}
                    parent={parent}
                    parentModelName={parentModelName}
                    activeStage={activeStage}
                    currPage={currPage}
                    setCurrPage={setCurrPage}
                    changePageState={changePageState}
                    setChangePageState={setChangePageState}
                />
            </div>
            <div style={{ width: "100%", height: "5px" }} >
                {loadingBool && (
                    <LinearProgress />
                )}
            </div>
            <div className={classes.bottomAct} >
                <ColorButton
                    variant="contained"
                    color="primary"
                    style={{ width: "40%" }}
                    disabled={needToMakeDisable}
                    onClick={() => {
                        setOpenAction(true)
                    }}
                >
                    Add Comment
                </ColorButton>
                <ApprovalActionDrawer
                    openAction={openAction}
                    setOpenAction={setOpenAction}
                    loadingBool={loadingBool}
                    setLoadingBool={setLoadingBool}
                    actionType={"addComment"}
                    parent={parent}
                    parentModelName={parentModelName}
                    activeStage={activeStage}
                    reloadChat={() => {
                        setCurrPage(0)
                        setChangePageState(!changePageState)
                    }}
                    requestId={requestId}
                />
            </div>
        </div>
    </>);
}
