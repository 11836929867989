import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getDetailsObservation } from "./Api.call";
import 'react-quill/dist/quill.snow.css';
import CreateIcon from '@material-ui/icons/Create';
import "../doc/editor.css"
import html2plaintext from "html2plaintext";
import LessText from "../styled/CommonComponents/LessText";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import OnPageApprovalActions from "../approval/OnPageApprovalActions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white",
    },
    backArrow: {
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
        },
    },
    mainBody: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "10px 25px 0px",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 10px 50px",
        },
    },
    topBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #ebeaea",
        paddingRight: "20px",
    },
    barLeft: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    barRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-label": {
            textTransform: "capitalize",
        },
    },
    headerTitle: {
        fontSize: "20px",
        fontWeight: "550",
        marginLeft: "10px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15px",
            fontWeight: "510",
            marginLeft: "0px",
        },
    },
    titleDescCont: {
        marginBottom: "30px",
        "& h3": {
            fontSize: "25px",
            fontWeight: "500",
            marginBottom: "5px",
            color: "black",
            textAlign: "center",
        },
        "& p": {
            fontSize: "15px",
            color: "black"
        }
    },
    cardSty: {
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#e2f3ff",
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
        "& div": {
            width: "100%",
            height: "200px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginBottom: "10px"
        },
        "& h4": {
            fontSize: "18px",
            textTransform: "capitalize",
            fontWeight: "500",
            marginBottom: "5px"
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "400",
        },
        "&:hover": {
            transform: "scale(1.03)",
            transition: "all 0.3s ease-in-out",
        },
    },
    drawerCont: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        backgroundColor: "#e2f3ff",
    },
    detailsDocSty: {
        padding: "20px",
        "& div": {
            width: "100%",
            height: "400px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            marginBottom: "10px"
        },
        "& h4": {
            fontSize: "22px",
            textTransform: "capitalize",
            fontWeight: "500",
            marginBottom: "5px"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "400",
        }
    }
}));

export default function PreviewObservation() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { observationId } = useParams();

    const [observation, setObservation] = useState();
    const [loader, setLoader] = useState(true);
    const [openDetails, setOpenDetails] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);

    //get populated observation data
    const getPopulatedObservationData = async () => {
        setLoader(true);
        await getDetailsObservation({ observationId })
            .then((data) => {
                console.log(data)
                if (data?._id) {
                    setObservation(data);
                }
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
            });
    }

    //get that data with useEffect
    useEffect(() => {
        if (observationId) {
            getPopulatedObservationData()
        }
    }, [observationId]);

    return (
        <div className={classes.root}>
            <div className={classes.topBar}>
                <div className={classes.barLeft}>
                    <IconButton
                        className={classes.iconButtonStyle}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <KeyboardBackspaceIcon className={classes.backArrow} />
                    </IconButton>
                    <Typography className={classes.headerTitle}>Observation</Typography>
                </div>
                <div className={classes.barRight}>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        startIcon={<CreateIcon />}
                        onClick={() => {
                            history.push(`/observation/edit/${observationId}`)
                        }}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <div style={{ height: "5px", width: "100%" }} >{loader ? <LinearProgress /> : null}</div>
            <div className={classes.mainBody}>
                {!loader && (<>
                    <div className={classes.titleDescCont} >
                        <h3>{observation?.title}</h3>
                        <p>{html2plaintext(observation?.description)}</p>
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={3} >
                            {observation?.docs && observation.docs.map((doc, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    <div
                                        className={classes.cardSty}
                                        onClick={() => {
                                            setSelectedDoc(doc)
                                            setOpenDetails(true)
                                        }}
                                    >
                                        <div
                                            style={{ backgroundImage: `url(${doc?.files[0]?.url})` }}
                                        ></div>
                                        <h4>{doc?.title}</h4>
                                        <p>
                                            <LessText
                                                limit={400}
                                                string={doc?.description}
                                            />
                                        </p>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    <NormalDrawer
                        openDrawer={openDetails}
                        setOpenDrawer={setOpenDetails}
                        anchor={"right"}
                        width={"60vw"}
                        title={"Details"}
                        content={<div className={classes.drawerCont} >
                            <div className={classes.detailsDocSty} >
                                <div
                                    style={{ backgroundImage: `url(${selectedDoc?.files[0]?.url})` }}
                                ></div>
                                <h4>{selectedDoc?.title}</h4>
                                <p>{selectedDoc?.description}</p>
                            </div>
                        </div>}
                    />
                    <OnPageApprovalActions
                        parent={observationId}
                        parentModelName={"SiteObservation"}
                        requestId={observation?.approvalRequest?._id}
                        needToMakeDisable={false}
                        activeStage={observation?.approvalRequest?.activeStage}
                    />
                </>)}
            </div>
        </div>
    );
}