import React, { useEffect, useRef, useState } from "react";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  Box, Grid, IconButton, ToggleButton,
  ToggleButtonGroup, Typography,
  Paper,
} from "@mui/material";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import FormBox from "../../styled/generic/FormBox";
import { Add, TaskAltOutlined } from "@mui/icons-material";
import Button from "../../styled/generic/Button";
import TextField from "../../styled/generic/TextField";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import * as XLSX from "xlsx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHeaderWithActions from "../../styled/generic/FormHeaderWithActions";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";
import TargetTasksList from "../tasks/TargetTasksList";
import { getTargetDetailsById } from "../api.call";
import { Breadcrumbs, Link } from '@mui/material';
import configObject from '../../../config/index'
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import socket from "../../../helpers/socket/socketio";
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import TargetAnalytics from './TargetAnalytics';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white"
  },
  emptySty: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  backArrow: {
    fontSize: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editIconSty: {
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  editBody: {
    width: "100%",
    height: "calc(100% - 50px)",
    overflow: "hidden",
  },
  topBar: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ebeaea",
    paddingRight: "20px",
  },
  barLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  barRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  loaderCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  breadcrumbs: {
    fontSize: "20px",
    fontWeight: "550",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      fontWeight: "510",
      marginLeft: "0px",
    },
  },
  loadingMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    fontWeight: 500,
    '& .emoji': {
      fontSize: '20px'
    }
  },
  blueText: {
    color: '#2196f3'
  },
  orangeText: {
    color: '#ff9800'
  },
  greenText: {
    color: '#4caf50'
  },
  purpleText: {
    color: '#9c27b0'
  }
}));

const PlanningTarget = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const filePickerRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const fileState = useSelector((state) => state.file);
  const { createdFileIds, fileDictionary } = useSelector((state) => state.file);

  const { targetId } = useParams();
  const history = useHistory();

  const [target, setTarget] = useState();
  const [loading, setLoading] = useState(false);
  const [taskView, setTaskView] = useState("list");
  const [fileData, setFileData] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [selectedSheetName, setSelectedSheetName] = useState(null);
  const [reload, setReload] = useState(false);
  const [userProfileId, setUserProfileId] = useState(null);
  const [orgProfileId, setOrgProfileId] = useState(null);
  const [projectProfileId, setProjectProfileId] = useState(null);
  const [selectedTables, setSelectedTables] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [uploadStatus, setUploadStatus] = useState({
    status: null,
    message: '',
    data: null
  });

  const [view, setView] = useState('tasksList'); // Add view state
  const [tasks, setTasks] = useState([]); // Add tasks state
  const [loadingMessage, setLoadingMessage] = useState('');

  useEffect(() => {
    setUserProfileId(user?.profile);
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  const shareBtn = (
    <Button variant="outlined" color="primary">
      Manage Resource Access
    </Button>
  )

  const {
    sharedProfilesRole, ShareButton, ShareDrawer, SharedView,
    assignedProfiles, AssignButton, AssigneDrawer, AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole, initAssigned: null, resourceId: targetId,
    resourceName: 'Target', customShareBtn: shareBtn, customAssignBtn: assignBtn,
  });

  const getLoadingMessageStyle = (message) => {
    switch(message) {
      case 'This takes about a minute for 2500 tasks':
        return classes.blueText;
      case 'Getting tasks':
        return classes.orangeText;
      case 'Getting parent child relationships':
        return classes.greenText;
      case 'Calculating cumulative completion percentage':
        return classes.purpleText;
      default:
        return '';
    }
  };

  const getLoadingMessageEmoji = (message) => {
    switch(message) {
      case 'This takes about a minute for 2500 tasks':
        return '⏳';
      case 'Getting tasks':
        return '📋';
      case 'Getting parent child relationships':
        return '🔄';
      case 'Calculating cumulative completion percentage':
        return '📊';
      default:
        return '';
    }
  };

  const getAnalytics = async () => {
    try {
      setLoading(true);
      setView('analytics');
      
      setLoadingMessage('This takes about a minute for 2500 tasks');
      
      const messageTimer = setInterval(() => {
        setLoadingMessage(current => {
          switch(current) {
            case 'This takes about a minute for 2500 tasks':
              return 'Getting tasks';
            case 'Getting tasks':
              return 'Getting parent child relationships';
            case 'Getting parent child relationships':
              return 'Calculating cumulative completion percentage';
            default:
              return current;
          }
        });
      }, 7000);

      const { data } = await Api.post('wbs/targetAnalytics', { targetId });
      setTasks(data);
      
      clearInterval(messageTimer);
      setLoadingMessage('');

    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch analytics",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const getTarget = async () => {
    try {
      setLoading(true);
      await getTargetDetailsById({ targetId })
        .then((data) => {
          if (data) {
            console.log(data,' getTarget  ')
            setTarget(data);
            setOrgProfileId(data?.organization?.profile)
            setProjectProfileId(data?.project?.profile)
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred while fetching target details",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching target details",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTarget();
  }, []);

  //import related functionalities---------------------------------------------------------------start

  // Add socket listener for file processing status
  useEffect(() => {
    if (targetId) {
      socket.on(`file-processing-${targetId}`, (data) => {
        setUploadStatus(data);
        
        // Reset status after completion or error
        if (data.status === 'complete' || data.status === 'error') {
          setTimeout(() => {
            setUploadStatus({ status: null, message: '', data: null });
            setShowUploadDialog(false);
            // Reload tasks list if successful
            if (data.status === 'complete') {
              setReload(prev => !prev);
            }
          }, 2000);
        }
      });

  


    }

    return () => {
      if (targetId) {
        socket.off(`file-processing-${targetId}`);
      }
    };
  }, [targetId]);

  const uploadTasks = async () => {
    try {
      const reqObj = {
        fileId: createdFileIds[0],
        target: targetId,
        project: target?.project?._id,
        organization: target?.organization?._id,
        creator: user?.profile,
      }

      await Api.post("wbs/upload", reqObj);
      
      // Reset file upload state
      dispatch({ type: "FileUploadReset" });
      
    } catch (err) {
      setUploadStatus({
        status: 'error',
        message: 'Failed to process file',
        error: err.message
      });
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'started':
        return 'info';
      case 'saving':
        return 'warning';
      case 'complete':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'info';
    }
  };

  //import related functionalities---------------------------------------------------------------end

  return (
    <div className={classes.root}>
      {loading ? (
        <div className={classes.loaderCont}>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <CircularProgress />
            {loadingMessage && (
              <Typography 
                className={`${classes.loadingMessage} ${getLoadingMessageStyle(loadingMessage)}`}
              >
                <span className="emoji">{getLoadingMessageEmoji(loadingMessage)}</span>
                {loadingMessage}
              </Typography>
            )}
          </Box>
        </div>
      ) : (
        <>
          {target?._id ? (<>
            <div className={classes.topBar}>

              <div className={classes.barLeft}>
                  <IconButton
                    className={classes.iconButtonStyle}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <KeyboardBackspaceIcon className={classes.backArrow} />
                  </IconButton>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator=">"
                    className={classes.breadcrumbs}
                  >
                    {target?.organization ? (
                      <Link
                        color="inherit"
                        onClick={() => {
                          const url = `${configObject.mode === 'prod' ? 'https://reallist.ai' : ''}/organization/${target.organization.team}`;
                          configObject.mode === 'prod' 
                            ? window.open(url, "_self")
                            : history.push(url);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {target.organization.name || 'Organization'}
                      </Link>
                    ) : null}
                    {target?.project ? (
                      <Link
                        color="inherit"
                        onClick={() => {
                          const url = `${configObject.mode === 'prod' ? 'https://reallist.ai' : ''}/project/${target.project.team}`;
                          configObject.mode === 'prod'
                            ? window.open(url, "_self")
                            : history.push(url);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {target.project.name || 'Project'}
                      </Link>
                    ) : null}
                    <Typography color="textPrimary">
                      {target?.title || "Untitled"}
                    </Typography>
                  </Breadcrumbs>
              </div>

              <div className={classes.barRight}>
                {ShareButton}
                {ShareDrawer}
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setShowUploadDialog(true)}
                >
                  Upload
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={getAnalytics}
                >
                  Analytics
                </Button>
                <ToggleButtonGroup
                  value={taskView}
                  exclusive
                  onChange={(evt, val) => setTaskView(val)}
                  size="small"
                  sx={{ marginLeft: "10px" }}
                >
                  <ToggleButton value="list">
                    <TaskAltOutlined />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <div className={classes.editBody}>

                {view === 'tasksList' ? (
                  <TargetTasksList target={target} reload={reload} />
                ) : (
                  <TargetAnalytics targetId={targetId} tasks={tasks} />
                )}


              <DrawerContainer
                open={showUploadDialog}
                setOpen={setShowUploadDialog}
                title="Upload MSP pdf"
              >
                <Box>
                  <Box>
                    <CustomFileUploadButton
                      showComponent={
                        <Button 
                          variant="outlined" 
                          color="primary"
                          disabled={uploadStatus.status === 'started' || uploadStatus.status === 'saving'}
                        >
                          Click here to select file
                        </Button>
                      }
                      parentType="WBS"
                      parentId={null}
                      fileNum={1}
                      givenFileTypes={['.pdf']}
                      givenMaxSize={26214400}
                      closeFunCall={() => {
                        console.log("close dialog");
                      }}
                    />
                    
                    {createdFileIds?.length > 0 && (
                      <>
                        <Paper 
                          elevation={1} 
                          sx={{ 
                            p: 2, 
                            mt: 2, 
                            mb: 2,
                            backgroundColor: '#f5f5f5'
                          }}
                        >
                          {fileDictionary[createdFileIds[0]]?.name}
                        </Paper>
                        
                        {uploadStatus.status && (
                          <Box sx={{ mt: 2, mb: 2 }}>
                            <Alert severity={getStatusColor(uploadStatus.status)}>
                              {uploadStatus.message}
                            </Alert>
                            {(uploadStatus.status === 'started' || uploadStatus.status === 'saving') && (
                              <LinearProgress sx={{ mt: 1 }} />
                            )}
                          </Box>
                        )}

                        <Box sx={{ mt: 2 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => uploadTasks()}
                            disabled={uploadStatus.status === 'started' || uploadStatus.status === 'saving'}
                          >
                            {uploadStatus.status === 'started' || uploadStatus.status === 'saving' 
                              ? 'Processing...' 
                              : 'Process File'
                            }
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </DrawerContainer>
            </div>
          </>) : (<div className={classes.emptySty} >
            Something went wrong!
          </div>)}
        </>
      )}
    </div>
  );
};
export default PlanningTarget;